import React, { useEffect, useRef } from 'react';

import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap"
import Seo from "../components/Seo";
import Section from '../components/Section';
import ListCheck from '../components/ListCheck';
import ListCheckItem from '../components/ListCheckItem';

const Home = ({ pageContext, data, location }) => {

  // init page metadata
  const { pages, variables, cookieConsentBarOpen, setCookieConsentBarOpen } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageHero
  } = data

  const bgImage = getImage(imageHero);

  // get breadcrumbs
  const { breadcrumb: { crumbs } } = pageContext

  // Suppress cookie consent bar
  const current = useRef(cookieConsentBarOpen)
  useEffect(() => {
    setCookieConsentBarOpen(false)
    return () => {
      setCookieConsentBarOpen(current)
    }
  }, [current, setCookieConsentBarOpen])

  return (
    <>
      <Seo title={thisPage?.Title} description={thisPage?.Description} />

      <Header />

      {/* 
        HERO SECTION
        */}
      <HeroSection
        title={thisPage?.Pagetitle}
        titleFullWidth={true}
        bgImage={bgImage}
        breadcrumbs={crumbs}
      />

      <Section>
        <Col className="col-lg-8">
          <h1 className="mb-4">Aarini and NL Digital Terms</h1>

          <p>These terms supplement and form part of your Agreement with Aarini for digital services and solutions. Aarini is committed to adhering to the NL Digital Terms and Conditions, ensuring transparency, compliance, and best practices in all engagements.</p>

          <h1 className="my-4">What It Means</h1>
          <p>The NL Digital Terms provide a standardized legal framework covering essential aspects of IT-related agreements, including software licensing, cloud services, consultancy, maintenance, and support.</p>

          <ListCheck>
            <ListCheckItem>Transparency & Consistency: A clear and uniform approach to digital contracts, reducing negotiation complexities.</ListCheckItem>
            <ListCheckItem>Industry-Recognized Standards: Developed in consultation with experts, ensuring fairness and reliability.</ListCheckItem>
            <ListCheckItem>Legal Compliance: Aligned with Dutch and EU regulations, offering a solid foundation for digital transactions. </ListCheckItem>
          </ListCheck>

          <h1 className="my-4">Accessing the Terms</h1>
          <p>You can download the latest version of the NL Digital Terms in both English and Dutch from our website:</p>
          <ul>
            <li><a href="/pdfs/nl-digital-terms-en.pdf" download>Download English Version</a></li>
            <li><a href="/pdfs/nl-digital-terms-nl.pdf" download>Download Dutch Version</a></li>
          </ul>
        </Col>
      </Section>
      <Footer />
    </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}`